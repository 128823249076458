import Vue from 'vue';
import TYPES from '@/types';

// Application
import GetEmergencyFundInvestorGoalQuery from '@/modules/flagship/emergency-fund-investor-goal/application/query/get-emergency-fund-investor-goal-query';
import GetCustomInvestorGoalQuery from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-query';
import GetRetirementInvestorGoalQuery from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import CalculateFixedTimeCalculationQuery from '@/modules/flagship/emergency-fund-calculations/fixed-time-calculation/application/querys/calculate-fixed-time-calculation-query';
import CalculateCustomGoalFixedTimeCalculationQuery from '@/modules/flagship/custom-goal-calculations/application/querys/calculate-custom-goal-fixed-time-calculation-query';
import CalculateRetirementGoalMonthlyPensionAdjustedCalculationQuery from '@/modules/flagship/retirement-goal-calculations/application/queries/calculate-retirement-goal-monthly-pension-adjusted-calculation-query';
import UpdateEmergencyFundInvestorGoalCommand from '@/modules/flagship/emergency-fund-investor-goal/application/command/update-emergency-fund-investor-goal-command';
import UpdateCustomInvestorGoalCommand from '@/modules/flagship/custom-investor-goal/application/command/update-custom-investor-goal-command';
import RetirementInvestorGoalCommand from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import GetInvestorProfileFlagshipProfilingQuery from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import UpdateInvestorProfileCommand from '@/modules/flagship/investor-profile/investor-profile/application/commands/update-investor-profile-command';
import CalculateOverallInvestmentAmountQuery from '@/modules/flagship/overall-investment-amount-calculation/application/queries/calculate-overall-investment-amount-query';
import UpdateInvestorGoalDatesCommand from '@/modules/flagship/investor-goal/investor-goal-dates/application/commands/update-investor-goal-dates-command';

// Domain
import { InvestorProfileDto } from '@/modules/flagship/investor-profile/investor-profile/domain/dtos/investor-profile-dto';
import { InvestorGoalDatesDto } from '@/modules/flagship/investor-goal/investor-goal-dates/domain/dtos/investor-goal-dates-dto';
import {
  EmergencyFundInvestorGoalDto,
} from '@/modules/flagship/emergency-fund-investor-goal/domain/dtos/emergency-fund-investor-goal-dto';
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

export default class FlagshipGoalsWizardAdjustGoalsViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileFlagshipProfilingQuery

  @Inject(TYPES.GET_EMERGENCY_FUND_INVESTOR_GOAL_QUERY)
  private readonly get_emergency_fund_investor_goal_query!: GetEmergencyFundInvestorGoalQuery;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_QUERY)
  private readonly get_custom_investor_goal_query!: GetCustomInvestorGoalQuery;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.CALCULATE_EMERGENCY_FUND_FIXED_TIME_CALCULATION_QUERY)
  private readonly calculate_emergency_fund_fixed_time_query!: CalculateFixedTimeCalculationQuery;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_FIXED_TIME_CALCULATION_QUERY)
  private readonly calculate_custom_goal_fixed_time_query!:
    CalculateCustomGoalFixedTimeCalculationQuery;

  @Inject(TYPES.CALCULATE_RETIREMENT_GOAL_MONTHLY_PENSION_ADJUSTED_CALCULATION_QUERY)
  private readonly calculate_retirement_goal_monthly_pension_query!:
    CalculateRetirementGoalMonthlyPensionAdjustedCalculationQuery;

  @Inject(TYPES.UPDATE_EMERGENCY_FUND_INVESTOR_GOAL_COMMAND)
  private readonly update_emergency_fund_command!: UpdateEmergencyFundInvestorGoalCommand;

  @Inject(TYPES.UPDATE_CUSTOM_INVESTOR_GOAL_COMMAND)
  private readonly update_custom_investor_goal_command!: UpdateCustomInvestorGoalCommand;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly update_retirement_investor_goal_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_COMMAND)
  private readonly update_investor_profile_command!: UpdateInvestorProfileCommand;

  @Inject(TYPES.CALCULATE_OVERALL_INVESTMENT_AMOUNT_QUERY)
  private readonly calculate_overall_investment_amount_query!:
    CalculateOverallInvestmentAmountQuery;

  @Inject(TYPES.UPDATE_INVESTOR_GOAL_DATES_COMMAND)
  private readonly update_investor_goal_dates_command!: UpdateInvestorGoalDatesCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_adjust_goals';

  emergency_fund_slider_max_value = 25000;

  emergency_fund_slider_min_value = 100;

  custom_goal_fund_slider_max_value = 50000;

  custom_goal_fund_slider_min_value = 100;

  retirement_goal_slider_max_value = 25000;

  monthly_required_amount = {
    emergency_fund_investor_goal_monthly_required_amount: 0,
    custom_investor_goal_monthly_required_amount: 0,
    retirement_investor_goal_monthly_required_amount: 0,
  };

  emergency_fund_monthly_payment = 0;

  emergency_fund_monthly_payment_field = 0;

  customized_goal_monthly_payment = 0;

  customized_goal_monthly_payment_field = 0;

  custom_goal_icon = '';

  retirement_goal_monthly_payment = 0;

  retirement_goal_monthly_payment_field = 0;

  retirement_goal_monthly_pension = 0;

  retirement_range_adjusted = 0;

  overall_investment_amount = 0;

  minimum_monthly_amount_required_retirement_goal = 0;

  emergency_fund_investor_goal_dto: EmergencyFundInvestorGoalDto = {
    id: '',
    fixed_time_adjusted: '0',
    investor_goal: {
      investor_profile_id: '',
      accumulated_amount: 0,
      monthly_required_amount: 0,
    },
  };

  custom_investor_goal_dto: CustomInvestorGoalDto = {
    id: '',
    fixed_time_adjusted: '0',
    goal_name: '',
    investor_goal: {
      investor_profile_id: '',
      accumulated_amount: 0,
      monthly_required_amount: 0,
    },
  };

  retirement_investor_goal_dto: RetirementInvestorGoalEntity = {
    id: '',
    monthly_pension_adjusted: '0',
    investor_goal: {
      investor_profile_id: '',
      accumulated_amount: 0,
      monthly_required_amount: '',
    },
  };

  private investor_profile_dto: InvestorProfileDto = {
    id: '',
    goals_completed: false,
  };

  private investor_goal_dates_dto_emergency: InvestorGoalDatesDto = {
    investor_goal_id: '',
    investment_goal_type_id: '',
    period: 0,
  }

  private investor_goal_dates_dto_retirement: InvestorGoalDatesDto = {
    investor_goal_id: '',
    investment_goal_type_id: '',
    period: 0,
  }

  private investor_goal_dates_dto_custom: InvestorGoalDatesDto = {
    investor_goal_id: '',
    investment_goal_type_id: '',
    period: 0,
  }

  is_valid_form = false;

  input_rules = {
    emergency_fund_monthly_payment_field: [requiredRule, (value: string) => minValueRule(value.toString().replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    customized_goal_monthly_payment_field: [requiredRule, (value: string) => minValueRule(value.toString().replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
    retirement_goal_monthly_payment_field: [requiredRule, (value: string) => minValueRule(
      value.toString().replace(/[^0-9.-]/g, ''),
      `${this.minimum_monthly_amount_required_retirement_goal}.00 MXN`,
      this.minimum_monthly_amount_required_retirement_goal,
    )],
  };

  timer?: NodeJS.Timer;

  is_loading = false;

  readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get emergency_fund_fixed_time_rounded() {
    return Math.round(Number(this.emergency_fund_investor_goal_dto.fixed_time_adjusted));
  }

  get customized_goal_fixed_time_rounded() {
    return Math.round(Number(this.custom_investor_goal_dto.fixed_time_adjusted));
  }

  get overall_investment_amount_formatted() {
    return Math.trunc(this.overall_investment_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get monthly_pension_formatted() {
    return Math.trunc(this.retirement_goal_monthly_pension).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading;
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_dto.id = investor_profile.id;
      this.emergency_fund_investor_goal_dto.investor_goal.investor_profile_id = investor_profile.id;
      this.custom_investor_goal_dto.investor_goal.investor_profile_id = investor_profile.id;
      this.retirement_investor_goal_dto.investor_goal.investor_profile_id = investor_profile.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  getEmergencyFundGoal = async () => {
    try {
      const emergency_fund_goal = await this.get_emergency_fund_investor_goal_query.execute();
      this.emergency_fund_investor_goal_dto.id = emergency_fund_goal.id;
      // eslint-disable-next-line max-len
      this.emergency_fund_investor_goal_dto.fixed_time_adjusted = String(emergency_fund_goal.fixed_time_adjusted);
      // eslint-disable-next-line max-len
      this.emergency_fund_investor_goal_dto.investor_goal.accumulated_amount = emergency_fund_goal.investor_goal.accumulated_amount;

      // eslint-disable-next-line max-len
      this.emergency_fund_monthly_payment = Math.trunc(emergency_fund_goal.investor_goal.monthly_required_amount);
      // eslint-disable-next-line max-len
      this.emergency_fund_monthly_payment_field = Math.trunc(emergency_fund_goal.investor_goal.monthly_required_amount);
      // eslint-disable-next-line max-len
      this.monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount = emergency_fund_goal.investor_goal.monthly_required_amount;
      this.investor_goal_dates_dto_emergency.investor_goal_id = emergency_fund_goal
        .investor_goal_id;
      this.investor_goal_dates_dto_emergency.investment_goal_type_id = emergency_fund_goal
        .investor_goal.investment_goal_type_id;
      this.investor_goal_dates_dto_emergency.period = emergency_fund_goal.fixed_time_adjusted;
      this.emergency_fund_slider_max_value = (
        emergency_fund_goal.desired_amount - emergency_fund_goal.investor_goal.initial_amount
      );
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_emergency_fund_goal'));
      }
    }
  };

  getCustomGoal = async () => {
    try {
      const custom_goal = await this.get_custom_investor_goal_query.execute(false);
      this.custom_investor_goal_dto.id = custom_goal.id;
      // eslint-disable-next-line max-len
      this.custom_investor_goal_dto.fixed_time_adjusted = String(custom_goal.fixed_time_adjusted.toFixed(2));
      this.custom_investor_goal_dto.goal_name = custom_goal.goal_name;
      // eslint-disable-next-line max-len
      this.custom_investor_goal_dto.investor_goal.accumulated_amount = custom_goal.investor_goal.accumulated_amount;
      // eslint-disable-next-line max-len
      this.customized_goal_monthly_payment = Math.trunc(custom_goal.investor_goal.monthly_required_amount);
      // eslint-disable-next-line max-len
      this.customized_goal_monthly_payment_field = Math.trunc(custom_goal.investor_goal.monthly_required_amount);
      // eslint-disable-next-line max-len
      this.monthly_required_amount.custom_investor_goal_monthly_required_amount = custom_goal.investor_goal.monthly_required_amount;
      this.custom_goal_icon = custom_goal.custom_goal_type.icon_name;
      this.investor_goal_dates_dto_custom.investor_goal_id = custom_goal.investor_goal_id;
      this.investor_goal_dates_dto_custom.investment_goal_type_id = custom_goal.investor_goal
        .investment_goal_type_id;
      this.investor_goal_dates_dto_custom.period = custom_goal.fixed_time_adjusted;
      this.custom_goal_fund_slider_max_value = custom_goal.desired_amount;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_custom_goal'));
      }
    }
  };

  getRetirementGoal = async () => {
    try {
      const retirement_goal = await this.get_retirement_investor_goal_query.execute(true);
      this.retirement_investor_goal_dto.id = retirement_goal.id;
      // eslint-disable-next-line max-len
      this.retirement_investor_goal_dto.investor_goal.accumulated_amount = retirement_goal.investor_goal.accumulated_amount;

      // eslint-disable-next-line max-len
      this.retirement_goal_monthly_payment = Math.trunc(retirement_goal.investor_goal.monthly_required_amount!);
      // eslint-disable-next-line max-len
      this.retirement_goal_monthly_payment_field = Math.trunc(retirement_goal.investor_goal.monthly_required_amount!);
      // eslint-disable-next-line max-len
      this.monthly_required_amount.retirement_investor_goal_monthly_required_amount = retirement_goal.investor_goal.monthly_required_amount!;
      this.minimum_monthly_amount_required_retirement_goal = this.monthly_required_amount
        .retirement_investor_goal_monthly_required_amount < 1 ? 0 : 1;
      this.retirement_goal_monthly_pension = retirement_goal.monthly_pension_adjusted;
      this.investor_goal_dates_dto_retirement.investor_goal_id = retirement_goal.investor_goal_id;
      this.investor_goal_dates_dto_retirement.investment_goal_type_id = retirement_goal
        .investor_goal.investment_goal_type_id || '';
      this.investor_goal_dates_dto_retirement.period = retirement_goal.retirement_range_adjusted;
      this.retirement_range_adjusted = retirement_goal.retirement_range_adjusted;
      this.retirement_goal_slider_max_value = retirement_goal.investor_goal.accumulated_amount || 0;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_retirement_goal'));
      }
    }
  };

  calculateOverallInvestmentAmount = async () => {
    try {
      // eslint-disable-next-line max-len
      const { overall_investment_amount_result } = await this.calculate_overall_investment_amount_query.execute(this.monthly_required_amount);
      this.overall_investment_amount = overall_investment_amount_result;
    } catch (e) {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_overall_investment_amount'));
    }
  };

  delayEmergencyFundField = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.changeEmergencyFundField();
    }, 1000);
  }

  changeEmergencyFundField = async () => {
    if (this.is_valid_form) {
      const monthly_payment = (String(this.emergency_fund_monthly_payment_field) !== '$0 MXN')
        ? Number(parseFloat(String(this.emergency_fund_monthly_payment_field).replaceAll(/[^\d.-]/g, '')).toFixed(2))
        : this.emergency_fund_slider_min_value;

      const max_monthly_payment_allowed = Math.trunc(this.emergency_fund_slider_max_value);

      this.emergency_fund_monthly_payment = monthly_payment < this.emergency_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      // eslint-disable-next-line max-len
      this.monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount = monthly_payment < this.emergency_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.emergency_fund_monthly_payment_field = monthly_payment
      // eslint-disable-next-line max-len
      < this.emergency_fund_slider_max_value ? monthly_payment : max_monthly_payment_allowed;
      await this.calculateEmergencyFundFixedTime();
    }
  };

  changeEmergencyFundSlider = async () => {
    // eslint-disable-next-line max-len
    this.monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount = this.emergency_fund_monthly_payment;
    this.emergency_fund_monthly_payment_field = this.emergency_fund_monthly_payment;
    await this.calculateEmergencyFundFixedTime();
  };

  calculateEmergencyFundFixedTime = async () => {
    try {
      this.is_loading = true;
      const calculation = await this.calculate_emergency_fund_fixed_time_query.execute({
        // eslint-disable-next-line max-len
        monthly_required_amount: this.monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount,
      });
      this.emergency_fund_investor_goal_dto.fixed_time_adjusted = String(calculation
        .emergency_fund_calculation.fixed_time_adjusted);
      this.investor_goal_dates_dto_emergency.period = calculation
        .emergency_fund_calculation.fixed_time_adjusted;
      await this.calculateOverallInvestmentAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_emergency_fund_fixed_time'));
    } finally {
      this.is_loading = false;
    }
  };

  delayCustomGoalField = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.changeCustomGoalField();
    }, 1000);
  }

  changeCustomGoalField = async () => {
    if (this.is_valid_form) {
      const monthly_payment = (String(this.customized_goal_monthly_payment_field) !== '$0 MXN')
        ? Number(parseFloat(String(this.customized_goal_monthly_payment_field).replaceAll(/[^\d.-]/g, '')).toFixed(2))
        : this.custom_goal_fund_slider_min_value;

      const max_monthly_payment_allowed = Math.trunc(this.custom_goal_fund_slider_max_value);
      // eslint-disable-next-line max-len
      this.customized_goal_monthly_payment = monthly_payment < this.custom_goal_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.monthly_required_amount.custom_investor_goal_monthly_required_amount = monthly_payment
      < this.custom_goal_fund_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.customized_goal_monthly_payment_field = monthly_payment
      < this.custom_goal_fund_slider_max_value ? monthly_payment
        : max_monthly_payment_allowed;
      await this.calculateCustomGoalFixedTime();
    }
  };

  changeCustomGoalSlider = async () => {
    // eslint-disable-next-line max-len
    this.monthly_required_amount.custom_investor_goal_monthly_required_amount = this.customized_goal_monthly_payment;
    this.customized_goal_monthly_payment_field = this.customized_goal_monthly_payment;
    await this.calculateCustomGoalFixedTime();
  };

  calculateCustomGoalFixedTime = async () => {
    try {
      this.is_loading = true;
      const calculation = await this.calculate_custom_goal_fixed_time_query.execute({
        custom_investor_goal_id: this.custom_investor_goal_dto.id,
        // eslint-disable-next-line max-len
        monthly_required_amount: this.monthly_required_amount.custom_investor_goal_monthly_required_amount,
      });
      this.custom_investor_goal_dto.fixed_time_adjusted = String(calculation
        .custom_investor_goal_calculation.fixed_time_adjusted);

      this.investor_goal_dates_dto_custom.period = calculation
        .custom_investor_goal_calculation.fixed_time_adjusted;
      await this.calculateOverallInvestmentAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_custom_goal_fixed_time'));
    } finally {
      this.is_loading = false;
    }
  };

  delayRetirementGoalField = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.changeRetirementGoalField();
    }, 1000);
  }

  changeRetirementGoalField = async () => {
    if (this.is_valid_form) {
      const monthly_payment = Number(parseFloat(String(this.retirement_goal_monthly_payment_field).replaceAll(/[^\d.-]/g, '')).toFixed(2));

      const max_monthly_payment_allowed = Math.trunc(this.retirement_goal_slider_max_value);

      this.retirement_goal_monthly_payment = monthly_payment < this.retirement_goal_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;
      // eslint-disable-next-line max-len
      this.monthly_required_amount.retirement_investor_goal_monthly_required_amount = monthly_payment
      < this.retirement_goal_slider_max_value
        ? monthly_payment : max_monthly_payment_allowed;

      this.retirement_goal_monthly_payment_field = monthly_payment
      < this.retirement_goal_slider_max_value ? monthly_payment
        : max_monthly_payment_allowed;
      await this.calculateRetirementGoalMonthlyPensionAdjusted();
    }
  };

  changeRetirementGoalSlider = async () => {
    // eslint-disable-next-line max-len
    this.monthly_required_amount.retirement_investor_goal_monthly_required_amount = this.retirement_goal_monthly_payment;
    this.retirement_goal_monthly_payment_field = this.retirement_goal_monthly_payment;
    await this.calculateRetirementGoalMonthlyPensionAdjusted();
  };

  calculateRetirementGoalMonthlyPensionAdjusted = async () => {
    try {
      this.is_loading = true;
      const calculation = await this.calculate_retirement_goal_monthly_pension_query.execute({
        // eslint-disable-next-line max-len
        monthly_required_amount: this.monthly_required_amount.retirement_investor_goal_monthly_required_amount,
      });
      this.retirement_goal_monthly_pension = calculation
        .retirement_calculation_data.monthly_pension_adjusted;
      this.retirement_investor_goal_dto.investor_goal.accumulated_amount = calculation
        .retirement_calculation_data.accumulated_amount;
      await this.calculateOverallInvestmentAmount();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_retirement_monthly_pension'));
    } finally {
      this.is_loading = false;
    }
  };

  patchEmergencyFundGoal = async () => {
    try {
      // eslint-disable-next-line max-len
      this.emergency_fund_investor_goal_dto.investor_goal.monthly_required_amount = this.monthly_required_amount.emergency_fund_investor_goal_monthly_required_amount;
      await this.update_emergency_fund_command.execute(this.emergency_fund_investor_goal_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_emergency_fund_investor_goal'));
      return false;
    }
  };

  patchCustomInvestorGoal = async () => {
    try {
      // eslint-disable-next-line max-len
      this.custom_investor_goal_dto.investor_goal.monthly_required_amount = this.monthly_required_amount.custom_investor_goal_monthly_required_amount;
      await this.update_custom_investor_goal_command.execute(this.custom_investor_goal_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_custom_investor_goal'));
      return false;
    }
  }

  patchRetirementInvestorGoal = async () => {
    try {
      // eslint-disable-next-line max-len
      this.retirement_investor_goal_dto.monthly_pension_adjusted = this.retirement_goal_monthly_pension.toFixed(2);
      // eslint-disable-next-line max-len
      this.retirement_investor_goal_dto.investor_goal.monthly_required_amount = this.monthly_required_amount.retirement_investor_goal_monthly_required_amount.toFixed(2);
      await this.update_retirement_investor_goal_command.execute(this.retirement_investor_goal_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_custom_investor_goal'));
      return false;
    }
  }

  updateInvestorProfile = async () => {
    try {
      this.investor_profile_dto.goals_completed = true;
      await this.update_investor_profile_command.execute(this.investor_profile_dto);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_investor_profile'));
      return false;
    }
  }

  patchInvestorGoalDatesEmergency = async () => {
    try {
      await this.update_investor_goal_dates_command.execute(this.investor_goal_dates_dto_emergency);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.patch_investor_goal_dates_emergency'));
      return false;
    }
  }

  patchInvestorGoalDatesCustom = async () => {
    try {
      await this.update_investor_goal_dates_command.execute(this.investor_goal_dates_dto_custom);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.patch_investor_goal_dates_custom'));
      return false;
    }
  }

  patchInvestorGoalDatesRetirement= async () => {
    try {
      // eslint-disable-next-line max-len
      await this.update_investor_goal_dates_command.execute(this.investor_goal_dates_dto_retirement);
      return true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.patch_investor_goal_dates_retirement'));
      return false;
    }
  }

  initialize = async () => {
    this.is_loading = true;
    this.view.$emit('loadingStep', true);
    await this.getInvestorProfile();
    await this.getEmergencyFundGoal();
    await this.getCustomGoal();
    await this.getRetirementGoal();
    await this.calculateOverallInvestmentAmount();
    this.view.$emit('loadingStep', false);
    this.is_loading = false;
  };

  onSubmit = async () => {
    this.is_loading = true;
    this.view.$emit('loadingStep', true);
    const stored_emergency = await this.patchEmergencyFundGoal();
    const stored_emergency_dates = await this.patchInvestorGoalDatesEmergency();
    const stored_custom = await this.patchCustomInvestorGoal();
    const stored_custom_dates = await this.patchInvestorGoalDatesCustom();
    const stored_retirement = await this.patchRetirementInvestorGoal();
    const stored_retirement_dates = await this.patchInvestorGoalDatesRetirement();
    const stored_investor_profile = await this.updateInvestorProfile();
    this.view.$emit('loadingStep', false);
    this.is_loading = false;
    if (stored_emergency && stored_emergency_dates && stored_custom && stored_custom_dates
      && stored_retirement && stored_retirement_dates && stored_investor_profile) {
      this.view.$emit('nextStep');
    }
  };
}
